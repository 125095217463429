<template>
  <DxDataGrid
    :show-borders="true"
    :data-source="dataSource"
    :remote-operations="true"
    :show-row-lines="true"
  >
    <DxColumn
      data-field="id"
      caption="No"
      :allow-editing="false"
      :width="100"
      :visible="false"
    >
      <DxFormItem :visible="false" />
    </DxColumn>
    <DxColumn data-field="name" caption="Kayıt"> </DxColumn>

    <DxSearchPanel :visible="true" />
    <DxFilterRow :visible="true" />
    <DxHeaderFilter :visible="true" />
    <DxGroupPanel :visible="false" />
    <DxScrolling mode="virtual" />
    <DxPaging :enabled="true" />
    <DxEditing
      :allow-adding="true"
      :allow-updating="false"
      :allow-deleting="false"
      mode="form"
    />
  </DxDataGrid>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxSearchPanel,
  DxScrolling,
  DxPaging,
  DxFormItem,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
} from "devextreme-vue/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import globalSettings from "../settings";

export default {
  components: {
    BCard,
    DxPaging,
    BCardText,
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxFormItem,
    DxScrolling,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRangeRule,
    DxRequiredRule,
    DxValueFormat,
  },
  props: ["kayitUrl"],
  data() {
    return {
      dataSource: null,
    };
  },
  created() {
    this.createDataSource();
  },
  methods: {
    createDataSource() {
      this.dataSource = createStore({
        key: "id",
        loadUrl: `${globalSettings.baseURL}` + this.kayitUrl + `/Read`,
        insertUrl: `${globalSettings.baseURL}` + this.kayitUrl + `/Create`,
        onBeforeSend: (method, ajaxOptions) => {
          var accessToken = localStorage.getItem("accessToken");
          ajaxOptions.headers = {
            Authorization: "Bearer " + accessToken,
          };
        },
      });
    },
  },
  watch: {
    kayitUrl: function () {
      this.createDataSource();
    },
  },
};
</script>

<style></style>
