<template>
  <b-overlay
    :show="show"
    rounded="sm"
    variant="primary"
    :opacity="0.3"
    blur="2px"
  >
    <b-row>
      <b-col cols="12">
        <b-button variant="success" @click="dataDoldur()">
          Otomatik Doldur
        </b-button>
      </b-col>
    </b-row>
    <b-row style="margin-top: 20px">
      <b-col cols="12">
        <form-wizard
          style="padding: 10px"
          color="#FF9F43"
          ref="AktifSihirbaz"
          title="ROMATEM Online Randevu Sistemi"
          :subtitle="null"
          layout="horizantal"
          class="wizard-vertical mb-3"
          next-button-text="İleri"
          back-button-text="Geri"
          :hideButtons="false"
        >
          <tab-content>
            <b-row style="margin-top: 20px">
              <b-col cols="12" class="mb-2">
                <h5>Kimlik Doğrulama</h5>
                <small class="text-muted">
                  Lütfen Aşağıdaki Bilgileri Doldurunuz</small
                >
              </b-col>
              <b-col md="6">
                <b-form-group label="Hastanın Adı">
                  <b-form-input
                    v-model="firstVerify.firstName"
                    placeholder="Hastanın Adı"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Hastanın Soyadı">
                  <b-form-input
                    v-model="firstVerify.lastName"
                    placeholder="Hastanın Soyadı"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="T.C. Kimlik Numarası">
                  <b-form-input
                    v-model="firstVerify.identityNumber"
                    type="number"
                    placeholder="T.C. Kimlik Numarası"
                    :state="identity"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Cinsiyet" label-for="v-country">
                  <b-form-select
                    style="margin-top: 7px"
                    v-model="firstVerify.gender"
                    :options="genders"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Gün">
                      <BFormSelect v-model="day" :options="days"></BFormSelect>
                    </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group label="Ay">
                      <BFormSelect
                        v-model="month"
                        :options="months"
                      ></BFormSelect>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Yıl">
                      <BFormSelect
                        v-model="year"
                        :options="years"
                      ></BFormSelect>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </tab-content>

          <!-- Hastane Seçimi -->
          <tab-content title="Onay Sayfası">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  Sayın <b>{{ firstVerify.firstName }}</b>
                </h5>
                <small class="text-muted">Lütfen KVKK Onayı veriniz</small>
              </b-col>

              <b-col md="6" class="form">
                <b-form-group
                  label="Telefon Numarası (Başında sıfır '0' olmadan giriniz)"
                >
                  <b-form-input
                    v-model="twoVerify.phoneNumber"
                    type="number"
                    placeholder="Telefon Numarası"
                    :state="phoneNumber"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </tab-content>

          <!-- Randevu Tamamla -->
          <tab-content title="Randevu Oluştur">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Randevu Oluştur</h5>
                <small class="text-muted"
                  >Lütfen Randevu Bilgilerini Seçiniz</small
                >
              </b-col>
              <b-col md="6">
                <b-form-group label="Hastane Seçiniz" label-for="v-country">
                  <b-form-select
                    style="margin-top: 7px"
                    v-model="selectedHospital"
                    :options="hospitalDatas"
                    value-field="code"
                    text-field="name"
                    @change="selectHospital(selectedHospital)"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Servis Seçiniz" label-for="v-country">
                  <b-form-select
                    style="margin-top: 7px"
                    v-model="selectedService"
                    :disabled="serviceDatas.length == 0"
                    :options="serviceDatas"
                    value-field="code"
                    text-field="name"
                    @change="selectSevice(selectedService)"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Doktor Seçiniz" label-for="v-country">
                  <b-form-select
                    style="margin-top: 7px"
                    v-model="selectedDoctor"
                    :options="doctorDatas"
                    :disabled="doctorDatas.length == 0"
                    value-field="code"
                    text-field="name"
                    @change="selectDoctor(selectedDoctor)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="divider my-2">
              <div class="divider-text">Randevu Tarihi ve Saati</div>
            </div>
            <div class="divider my-2">
              <div class="divider-text">
                <b-row>
                  <b-col cols="6">
                    <b-button
                      variant="warning"
                      @click="oncekiHafta"
                      style="padding: 6px"
                      v-if="this.times.length > 1"
                      :disabled="this.doctorAvailableTime.day == 0"
                    >
                      Önceki Hafta
                    </b-button>
                  </b-col>
                  <b-col cols="6">
                    <b-button
                      variant="warning"
                      style="padding: 6px"
                      @click="sonrakiHafta"
                      v-if="this.times.length > 1"
                    >
                      Sonraki Hafta
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </div>

            <b-row>
              <b-col
                cols="12"
                class="mb-2"
                style="display: inline-flex"
                id="myDIV"
              >
                <table v-for="timeItem in times">
                  <tr>
                    <th class="header">
                      {{ timeItem.name | formatDate }}
                    </th>
                  </tr>
                  <tr v-for="availableTime in timeItem.availableTimes">
                    <td>
                      <b-button
                        style="width: 110px; padding: 2px"
                        :variant="
                          availableTime.isAvailable
                            ? 'outline-success'
                            : 'outline-danger'
                        "
                        :disabled="!availableTime.isAvailable"
                        @click="selectTime(availableTime, timeItem.dutyId)"
                      >
                        {{ availableTime.startTime | formatTime }}-{{
                          availableTime.endTime | formatTime
                        }}
                      </b-button>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </tab-content>

          <template slot="footer">
            <div class="wizard-footer-right" style="margin-top: 20px">
              <b-button
                variant="success"
                @click="selectNext"
                v-if="this.activeTabIndex < 2"
              >
                İleri
              </b-button>
              <b-button
                variant="success"
                @click="randevuOnay"
                v-if="this.activeTabIndex > 1"
              >
                Randevu Al
              </b-button>
            </div>
            <div class="wizard-footer-left" style="margin-top: 20px">
              <b-button
                variant="danger"
                @click="selectBack"
                v-if="this.activeTabIndex > 0"
              >
                Geri
              </b-button>
            </div>
          </template>

          <template slot="step"><div></div></template>
        </form-wizard>
      </b-col>
    </b-row>
    <b-modal
      ref="popupOnay"
      title="Randevu Onayı"
      button-size="lg"
      cancel-title="Hayır"
      ok-title="Evet"
      @ok="randevuOnayla"
      centered
    >
      <b-card-text>
        <b>Hastane : {{ this.selectedHospitalText.name }}</b
        ><br />
        <b>Doktor: {{ this.selectedDoctorText.name }}</b>
        <br />
        <b>Saat: {{ this.selectedTimeFormat | formatDateTime }}</b>
        <br />
      </b-card-text>
      <b-card-text>
        Oluşturmak istediğiniz randevu bilgilerinin doğruluğundan eminmisiniz
        ?</b-card-text
      >
    </b-modal>
  </b-overlay>
</template>

<script>
import axios from "axios";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BCard,
  BCardText,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCollapse,
  BOverlay,
  BButton,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as lang from "vuejs-datepicker/src/locale";
import datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  components: {
    BCard,
    BCardText,
    datepicker,
    ToastificationContent,
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormSelect,
    vSelect,
    FormWizard,
    TabContent,
    axios,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCollapse,
    BButton,
    BFormCheckbox,
    BCardText,
    moment,
    BOverlay,
  },
  props: ["callPatient"],

  data() {
    return {
      dataSource: null,
      patientComing: "",
      activeTabIndex: 0,
      apiType: 0,
      day: "",
      month: "",
      months: [
        { value: "01", text: "Ocak" },
        { value: "02", text: "Şubat" },
        { value: "03", text: "Mart" },
        { value: "04", text: "Nisan" },
        { value: "05", text: "Mayıs" },
        { value: "06", text: "Haziran" },
        { value: "07", text: "Temmuz" },
        { value: "08", text: "Ağustos" },
        { value: "09", text: "Eylül" },
        { value: "10", text: "Ekim" },
        { value: "11", text: "Kasım" },
        { value: "12", text: "Aralık" },
      ],
      year: "",
      hospitalDatas: [],
      serviceDatas: [],
      doctorDatas: [],
      selectedHospital: null,
      selectedService: null,
      selectedDoctor: null,
      isLoading: true,
      firstVerify: {
        firstName: "",
        lastName: "",
        identityNumber: "",
        birthDate: null,
        gender: null,
        securityCode: "RECAPTCH",
      },
      verifyOk: "okey",
      selectedTime: "",
      twoVerify: { phoneNumber: "", isCampaign: true },
      randevuRequest: {
        apiType: 0,
        branchId: 0,
        doctorId: 0,
        doctorUnitId: 0,
        dutyId: "",
        unitType: "",
        startTime: "",
        endTime: "",
        who: "",
      },
      randevuBilgiData: "",
      kvkkVerify: true,
      doctorAvailableTime: {
        branchId: "",
        doctorId: "",
        day: "",
        doctorUnitId: "",
        doctorUnitType: "",
      },
      languages: lang,
      format: "dd/MM/yyyy",
      genders: [
        { value: true, text: "Erkek" },
        { value: false, text: "Kadın" },
      ],
      days: [],
      dayOptions: [],
      year: "",
      years: [],
      minDateValue: this.minDate,
      maxDateValue: this.maxDate,
      currentYear: new Date().getFullYear(),
      apiKey: [],
      times: [],
      selectedHospitalText: "",
      selectedDoctorText: "",
      selectedTimeFormat: "",
      show: false,
    };
  },
  created() {
    this.geleniBas();
    this.populateYear();
    this.populateDay();
    var uname = JSON.parse(localStorage.getItem("callCenterUserData"));
    this.randevuRequest.who = uname.username;
  },
  computed: {
    identity() {
      return this.firstVerify.identityNumber.length == 11 ? true : false;
    },
    phoneNumber() {
      return this.twoVerify.phoneNumber.length == 10 ? true : false;
    },
  },
  methods: {
    async geleniBas() {
      this.patientComing = this.callPatient;
      this.dataDoldur();
    },
    async dataDoldur() {
      this.firstVerify.firstName = this.callPatient.firstName;
      this.firstVerify.lastName = this.callPatient.lastName;
      this.twoVerify.phoneNumber = this.callPatient.phoneNumber;
    },
    async selectNext() {
      this.show = true;
      //   this.firstVerify.firstName = "Ahmet Emin";
      //   this.firstVerify.lastName = "Kurt";
      //   this.firstVerify.identityNumber = "53383271406";
      //   this.firstVerify.gender = true;
      //   this.day = "27";
      //   this.month = "07";
      //   this.year = "1992";
      //   this.verifyOk = "okey";

      if (this.firstVerify.identityNumber.length != 11) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Hata !",
              icon: "BellIcon",
              text: "Lütfen T.C.Kimlik Bilgisini Doğru Girdiğinizden Emin Olun.",
              variant: "danger",
            },
          },
          {
            position: "bottom-center",
          }
        );
        this.show = false;
        return;
      }
      if (
        this.firstVerify.firstName == null ||
        this.firstVerify.firstName == ""
      ) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Hata !",
              icon: "BellIcon",
              text: "Lütfen Ad Bilgisi Giriniz.",
              variant: "danger",
            },
          },
          {
            position: "bottom-center",
          }
        );
        this.show = false;
        return;
      }
      if (
        this.firstVerify.lastName == null ||
        this.firstVerify.lastName == ""
      ) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Hata !",
              icon: "BellIcon",
              text: "Lütfen Soyad Bilgisi Giriniz.",
              variant: "danger",
            },
          },
          {
            position: "bottom-center",
          }
        );
        this.show = false;
        return;
      }
      if (
        this.firstVerify.identityNumber == null ||
        this.firstVerify.identityNumber == ""
      ) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Hata !",
              icon: "BellIcon",
              text: "Lütfen Kimlik No Giriniz.",
              variant: "danger",
            },
          },
          {
            position: "bottom-center",
          }
        );
        this.show = false;
        return;
      }

      if (this.firstVerify.gender == null) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Hata !",
              icon: "BellIcon",
              text: "Lütfen Cinsiyet Bilgisi Giriniz.",
              variant: "danger",
            },
          },
          {
            position: "bottom-center",
          }
        );
        this.show = false;
        return;
      }

      if (
        this.year == null ||
        this.year == "" ||
        this.month == null ||
        this.month == "" ||
        this.day == null ||
        this.day == ""
      ) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Hata !",
              icon: "BellIcon",
              text: "Lütfen Doğum Tarihi Giriniz.",
              variant: "danger",
            },
          },
          {
            position: "bottom-center",
          }
        );
        this.show = false;
        return;
      }
      var today = new Date();
      var birthJson =
        this.year +
        "-" +
        this.month +
        "-" +
        this.day +
        " " +
        today.getHours() +
        ":" +
        today.getMinutes() +
        ":" +
        today.getSeconds();

      this.firstVerify.birthDate = new Date(birthJson);

      // Bu kısma kadar kontroller dolu mu boş mu ?
      /////
      // Bu kısım ise ilk tab kontrolü
      if (this.$refs.AktifSihirbaz.activeTabIndex == 0) {
        if (this.verifyOk != "okey") {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Hata !",
                icon: "BellIcon",
                text: "Lütfen Recaptcha Doğrulamasını Tamamlayınız.",
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
          this.show = false;
          return;
        }

        await axios
          .post(
            "https://randevuapi.romatem.com/api/Identity/Verification",
            this.firstVerify,
            {
              headers: {
                // remove headers
              },
            }
          )
          .then((response) => {
            this.apiKey = response.data.apiKey;
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Başarılı",
                  icon: "BellIcon",
                  text: "Doğrulama İşlemi Başarılı",
                  variant: "success",
                },
              },
              {
                position: "bottom-center",
              }
            );

            this.$refs.AktifSihirbaz.nextTab();
            this.activeTabIndex = this.$refs.AktifSihirbaz.activeTabIndex;
          })
          .catch((err) => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Hata !",
                  icon: "BellIcon",
                  text: err.response.data.message,
                  variant: "danger",
                },
              },
              {
                position: "bottom-center",
              }
            );
          });
        this.show = false;
        return;
      }

      if (this.$refs.AktifSihirbaz.activeTabIndex == 1) {
        if (this.twoVerify.phoneNumber.length != 10) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Hata !",
                icon: "BellIcon",
                text: "Lütfen Telefon Numaranızı Doğru Girdiğinizden Emin Olun.",
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
          this.show = false;
          return;
        }

        if (
          this.twoVerify.phoneNumber == "" ||
          this.twoVerify.phoneNumber == null
        ) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Hata !",
                icon: "BellIcon",
                text: "Lütfen Telefon Numaranızı Doğru Giriniz.",
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
          this.show = false;
          return;
        }
        if (this.kvkkVerify == false) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Hata !",
                icon: "BellIcon",
                text: "Lütfen KVKK Onayı Veriniz",
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
          this.show = false;
          return;
        }
        await axios
          .post(
            "https://randevuapi.romatem.com/api/Identity/AddExternalInformation",
            this.twoVerify,
            {
              headers: {
                RomatemKey: this.apiKey,
              },
            }
          )
          .then((response) => {
            this.apiKey = response.data.apiKey;
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Başarılı",
                  icon: "BellIcon",
                  text: "Telefon Doğrulama İşlemi Başarılı",
                  variant: "success",
                },
              },
              {
                position: "bottom-center",
              }
            );
            this.$refs.AktifSihirbaz.nextTab();
            this.activeTabIndex = this.$refs.AktifSihirbaz.activeTabIndex;
            this.getHospital();
          })
          .catch((err) => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Hata !",
                  icon: "BellIcon",
                  text: err.response.data.message,
                  variant: "danger",
                },
              },
              {
                position: "bottom-center",
              }
            );
          });
        this.show = false;
        return;
      }
    },
    async getHospital() {
      this.show = true;

      var responseHospital = await axios.get(
        "https://randevuapi.romatem.com/api/Branches",
        {
          headers: {
            RomatemKey: this.apiKey,
          },
        }
      );
      this.hospitalDatas = responseHospital.data;
      this.show = false;
    },
    async selectHospital(selectedHospital) {
      this.show = true;
      this.doctorDatas = [];
      this.serviceDatas = [];
      this.times = [];
      this.selectedService = null;
      this.selectedDoctor = null;

      this.apiType = this.hospitalDatas.filter(
        (q) => q.code == selectedHospital
      )[0].apiType;

      await axios
        .get(
          "https://randevuapi.romatem.com/api/Units/GetListByBranchId?branchId=" +
            selectedHospital +
            "&apiType=" +
            this.apiType,
          {
            headers: {
              RomatemKey: this.apiKey,
            },
          }
        )
        .then((responseServices) => {
          this.serviceDatas = responseServices.data;
          this.randevuRequest.branchId = selectedHospital;
          this.show = false;
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Hata !",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
          this.show = false;
        });
    },
    async selectSevice(selectedService) {
      this.show = true;
      this.times = [];
      this.selectedDoctor = null;
      await axios
        .get(
          "https://randevuapi.romatem.com/api/Doctors/GetListByBranchId?branchId=" +
            this.selectedHospital +
            "&unitId=" +
            selectedService +
            "&apiType=" +
            this.apiType,
          {
            headers: {
              RomatemKey: this.apiKey,
            },
          }
        )
        .then((responseDoctors) => {
          this.doctorDatas = responseDoctors.data;
          this.show = false;
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Hata !",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
          this.show = false;
        });
    },
    async selectDoctor(selectedDoctor) {
      this.show = true;

      var selectedDoctorData = this.doctorDatas.filter(
        (s) => s.code == selectedDoctor
      )[0];
      //console.log(selectedDoctorData);
      this.doctorAvailableTime.branchId = this.selectedHospital;
      this.doctorAvailableTime.doctorId = selectedDoctorData.code;
      this.doctorAvailableTime.day = 0;
      this.doctorAvailableTime.doctorUnitId = selectedDoctorData.unitId;
      this.doctorAvailableTime.doctorUnitType = selectedDoctorData.unitType;

      this.randevuRequest.apiType = selectedDoctorData.apiType;
      this.randevuRequest.unitType = selectedDoctorData.unitType;
      this.randevuRequest.doctorUnitId = selectedDoctorData.unitId;
      this.randevuRequest.doctorId = selectedDoctorData.code;

      await axios
        .post(
          "https://randevuapi.romatem.com/api/Doctors/GetAvailableTimes",
          this.doctorAvailableTime,
          {
            headers: {
              RomatemKey: this.apiKey,
            },
          }
        )
        .then((response) => {
          this.times = response.data.availableDays;
          //console.log(this.times);
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Başarılı",
                icon: "BellIcon",
                text: "Seçtiğiniz Doktorun Haftalık Saatleri Yüklendi",
                variant: "success",
              },
            },
            {
              position: "bottom-center",
            }
          );
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Hata !",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });

      this.show = false;
    },
    async oncekiHafta() {
      this.show = true;
      this.doctorAvailableTime.day -= 7;
      await axios
        .post(
          "https://randevuapi.romatem.com/api/Doctors/GetAvailableTimes",
          this.doctorAvailableTime,
          {
            headers: {
              RomatemKey: this.apiKey,
            },
          }
        )
        .then((response) => {
          this.times = response.data.availableDays;
          //console.log(this.times);
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Başarılı",
                icon: "BellIcon",
                text: "Önceki Hafta Saatleri Yüklendi",
                variant: "success",
              },
            },
            {
              position: "bottom-center",
            }
          );
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Hata !",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });

      this.show = false;
    },
    async sonrakiHafta() {
      this.show = true;
      this.doctorAvailableTime.day += 7;
      await axios
        .post(
          "https://randevuapi.romatem.com/api/Doctors/GetAvailableTimes",
          this.doctorAvailableTime,
          {
            headers: {
              RomatemKey: this.apiKey,
            },
          }
        )
        .then((response) => {
          this.times = response.data.availableDays;
          //console.log(this.times);
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Başarılı",
                icon: "BellIcon",
                text: "Sonraki Hafta Saatleri Yüklendi",
                variant: "success",
              },
            },
            {
              position: "bottom-center",
            }
          );
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Hata !",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });

      this.show = false;
    },
    async selectTime(availableTime, dutyId) {
      this.selectedTime = availableTime;

      this.randevuRequest.startTime = availableTime.startTime;
      this.randevuRequest.endTime = availableTime.endTime;
      this.randevuRequest.dutyId = dutyId;
    },
    async randevuOnay() {
      if (this.$refs.AktifSihirbaz.activeTabIndex == 2) {
        this.show = true;
        // console.log(this.selectedService);
        if (this.selectedService == null) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Hata !",
                icon: "BellIcon",
                text: "Lütfen Önce Servis Seçimi Yapınız.",
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
          this.show = false;
          return;
        }

        if (this.selectedDoctor == null) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Hata !",
                icon: "BellIcon",
                text: "Lütfen Önce Doktor Seçimi Yapınız.",
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
          this.show = false;
          return;
        }
        if (this.randevuRequest.startTime == "") {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Hata !",
                icon: "BellIcon",
                text: "Lütfen Saat Seçimi Yapınız.",
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
          this.show = false;
          return;
        }
        this.$refs["popupOnay"].show();
        //console.log(this.randevuRequest);
        this.selectedDoctorText = this.doctorDatas.filter(
          (s) => s.code == this.randevuRequest.doctorId
        )[0];
        this.selectedHospitalText = this.hospitalDatas.filter(
          (s) => s.code == this.randevuRequest.branchId
        )[0];
        this.selectedTimeFormat = this.randevuRequest.startTime;
        this.show = false;
        return;
      }
    },
    async randevuOnayla() {
      this.show = true;
      var saat = moment(this.selectedTimeFormat).format("DD/MM/YYYY - HH:mm");

      var messageText =
        "Sayın, " +
        this.firstVerify.firstName +
        " " +
        this.firstVerify.lastName +
        ", " +
        "Hastane: " +
        this.selectedHospitalText.name +
        ", " +
        "Doktor: " +
        this.selectedDoctorText.name +
        ", " +
        "Tarih ve Saat: " +
        saat +
        " Randevunuz Oluşturulmuştur. - Randevu Kodu: @TRACKERCODE";

      this.randevuRequest.message = messageText;

      await axios
        .post(
          "https://randevuapi.romatem.com/api/Appointments/Create",
          this.randevuRequest,
          {
            headers: {
              RomatemKey: this.apiKey,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Başarılı",
                icon: "BellIcon",
                text: "Seçtiğiniz Randevu Başarılı Şekilde Oluşturuldu",
                variant: "success",
              },
            },
            {
              position: "bottom-center",
            }
          );
          this.show = false;
          this.randevuBilgiData = response.data.message;
          this.$refs.AktifSihirbaz.prevTab();
          this.$refs.AktifSihirbaz.prevTab();
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Hata !",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
      this.show = false;
      return;
    },
    selectBack() {
      if (this.$refs.AktifSihirbaz.activeTabIndex == 1) {
        this.$refs.AktifSihirbaz.prevTab();
        this.activeTabIndex = this.$refs.AktifSihirbaz.activeTabIndex;
        return;
      }
      if (this.$refs.AktifSihirbaz.activeTabIndex == 2) {
        this.$refs.AktifSihirbaz.prevTab();
        this.activeTabIndex = this.$refs.AktifSihirbaz.activeTabIndex;
        return;
      }
    },
    async populateYear() {
      var currentYear = new Date().getFullYear(),
        startYear = startYear || 1900;

      while (startYear <= currentYear) {
        this.years.push(startYear++);
      }
    },
    async populateDay() {
      //   console.log('populate day');
      var day,
        start1 = 1,
        start2 = 10,
        end1 = 9,
        end2 = 31,
        month = parseInt(this.month),
        year = parseInt(this.year);

      if (
        !this.allowPast &&
        year === this.currentYear &&
        month === this.currentMonth &&
        start1 < this.currentDay
      ) {
        start1 = this.currentDay;
      }

      if (this.maxAge != null) {
        if (
          year === this.currentYear - this.maxAge &&
          month === this.currentMonth
        ) {
          start1 = this.currentDay;
        }
      }

      if (
        this.minDateValue !== null &&
        new Date(this.minDateValue).getFullYear() === year &&
        new Date(this.minDateValue).getMonth() + 1 === month
      ) {
        start1 =
          start1 < new Date(this.minDateValue).getDate()
            ? new Date(this.minDateValue).getDate()
            : start1;
      }

      if (start2 < start1) {
        start2 = start1;
      }

      var numDaysInMonth = new Date(year, month, 0).getDate();
      if (end2 > numDaysInMonth) {
        end2 = numDaysInMonth;
      }

      if (
        !this.allowFuture &&
        year === this.currentYear &&
        month === this.currentMonth &&
        end2 > this.currentDay
      ) {
        end2 = this.currentDay;
      }

      if (this.minAge != null) {
        if (
          year === this.currentYear - this.minAge &&
          month === this.currentMonth
        ) {
          end2 = this.currentDay;
        }
      }

      if (
        this.maxDateValue !== null &&
        new Date(this.maxDateValue).getFullYear() === year &&
        new Date(this.maxDateValue).getMonth() + 1 === month
      ) {
        end2 =
          end2 > new Date(this.maxDateValue).getDate()
            ? new Date(this.maxDateValue).getDate()
            : end2;
      }

      if (end1 > start2) {
        start2 = end1;
      }
      if (start2 > end2) {
        end2 = start2;
      }

      this.days = [];
      this.dayOptions = [];

      if (this.dayLabel) {
        this.dayOptions.push(this.dayLabel);
        this.days.push(null);
      }

      // Days 1-9
      for (var i = start1; i <= end1; i++) {
        if (this.daySuffixes) {
          day = i;
        } else {
          day = "0" + i;
        }
        this.days.push(i);
        this.dayOptions.push(day);
      }

      // Days 10-31
      for (var j = start2; j <= end2; j++) {
        day = j;

        if (this.daySuffixes) {
          day = j;
        }
        this.days.push(j);
        this.dayOptions.push(day);
      }
    },
  },
  watch: {
    callPatient: function () {
      this.geleniBas();
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.header {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  background: #ffffff;
  border: 1px solid #c9c9c9;
  padding: 6px;
}
table {
  border-collapse: collapse;
  width: 100%;
  height: fit-content;
}

td,
th {
  text-align: center;
  padding: 5px;
}

#myDIV {
  width: 250px;
  overflow: auto;
}

@media screen and (max-width: 600px) {
  .nomobile {
    width: 100% !important;
  }
}
</style>
