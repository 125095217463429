<template>
  <div>
    <b-card>
      <b-tabs content-class="pt-1" fill>
        <b-tab title="Çağrı Kayıt" active>
          <b-row>
            <b-col md="3">
              <b-col cols="12">
                <b-form-group>
                  <b-form-checkbox v-model="callRequest.incoming">
                    Gelen Çağrı mı ?
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Telefon Numarası (Başında sıfır '0' olmadan giriniz)"
                  label-for="defaultLabel"
                >
                  <b-row>
                    <b-col cols="10">
                      <b-form-input
                        size="sm"
                        id="demo"
                        v-model="patientRequerst.phoneNumber"
                        type="number"
                        placeholder="Telefon Numarası"
                        :state="phoneNumber"
                        :disabled="phoneAktif"
                      /> </b-col
                    ><b-col cols="2">
                      <b-button
                        variant="success"
                        @click="numaraSorgula()"
                        style="float: right; margin-left: 10px; padding: 7px"
                      >
                        Ara
                      </b-button>
                    </b-col></b-row
                  >
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label-for="defaultLabel">
                  <b-form-input
                    size="sm"
                    id="defaultLabel"
                    placeholder="Hasta Ad"
                    v-model="patientRequerst.firstName"
                    :disabled="firstNameAktif"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label-for="defaultLabel">
                  <b-form-input
                    size="sm"
                    id="defaultLabel"
                    placeholder="Hasta Soyad"
                    v-model="patientRequerst.lastName"
                    :disabled="lastNameAktif"
                  />
                </b-form-group>
              </b-col>
              <!-- Arayan Hastamı -->
              <b-col cols="12">
                <b-form-group>
                  <b-form-checkbox v-model="callRequest.isRelative">
                    Arayan Hasta mı?
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col cols="12" v-if="callRequest.isRelative == false">
                <b-form-group label-for="defaultLabel">
                  <b-form-input
                    size="sm"
                    id="defaultLabel"
                    placeholder="Hasta Yakını Ad"
                    v-model="callRequest.relativeFirstName"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" v-if="callRequest.isRelative == false">
                <b-form-group label-for="defaultLabel">
                  <b-form-input
                    size="sm"
                    id="defaultLabel"
                    placeholder="Hasta Yakını Soyad"
                    v-model="callRequest.relativeLastName"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <v-select
                    placeholder="Şehir"
                    class="select-size-sm"
                    v-model="callRequest.provinceId"
                    :getOptionLabel="(provinces) => provinces.name"
                    :reduce="(provinces) => provinces.id"
                    :options="provinces"
                    @input="ilceGetir()"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <v-select
                    placeholder="İlçe"
                    class="select-size-sm"
                    v-model="callRequest.districtId"
                    :getOptionLabel="(districts) => districts.name"
                    :reduce="(districts) => districts.id"
                    :options="districts"
                    :disabled="districts.length == 0"
                  />
                </b-form-group>
              </b-col>
              <!-- <b-col cols="12">
                <b-button variant="success" @click="gonderConsole()">
                  gönder
                </b-button>
              </b-col> -->

              <!-- görevli bitti -->
            </b-col>
            <b-col md="3">
              <!-- Arayan Hastamı -->

              <!-- görevli basladi -->
              <!-- Tab gecis -->
              <b-tabs>
                <b-tab title="Hastaneden Bul">
                  <b-col cols="12">
                    <b-form-group>
                      <v-select
                        placeholder="Hastane"
                        class="select-size-sm"
                        v-model="callRequest.hospitalId"
                        :getOptionLabel="(hospitals) => hospitals.name"
                        :reduce="(hospitals) => hospitals.id"
                        :options="hospitals"
                        @input="departmanGetir()"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <v-select
                        placeholder="Bölüm"
                        class="select-size-sm"
                        v-model="callRequest.departmentId"
                        :getOptionLabel="(departments) => departments.name"
                        :reduce="(departments) => departments.id"
                        :options="departments"
                        :disabled="departments.length == 0"
                        @input="gorevGetir()"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <v-select
                        placeholder="Görev"
                        class="select-size-sm"
                        v-model="callRequest.questId"
                        :getOptionLabel="(quests) => quests.name"
                        :reduce="(quests) => quests.id"
                        :options="quests"
                        :disabled="quests.length == 0"
                        @input="gorevlileriGetir()"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <v-select
                        placeholder="Görevli Kişi"
                        class="select-size-sm"
                        v-model="callRequest.employeeId"
                        :getOptionLabel="
                          (employeQuests) => employeQuests.fullName
                        "
                        :reduce="(employeQuests) => employeQuests.id"
                        :options="employeQuests"
                        :disabled="employeQuests.length == 0"
                        @input="getGorevliBilgileri(employeQuests)"
                      />
                    </b-form-group> </b-col
                ></b-tab>
                <!-- Tab gecis -->
                <b-tab title="Çalışan İsminden Bul" active>
                  <b-col cols="12">
                    <b-form-group>
                      <v-select
                        placeholder="Görevli Kişi"
                        class="select-size-sm"
                        v-model="callRequest.employeeId"
                        :getOptionLabel="(calisanlar) => calisanlar.fullName"
                        :reduce="(calisanlar) => calisanlar.id"
                        :options="calisanlar"
                        @input="getGorevliBilgigoster(calisanlar)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <b-form-input
                        size="sm"
                        id="defaultLabel"
                        placeholder="Hastane"
                        v-model="employeselected.hospital"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <b-form-input
                        size="sm"
                        id="defaultLabel"
                        placeholder="Bölüm"
                        v-model="employeselected.department"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <b-form-input
                        size="sm"
                        id="defaultLabel"
                        placeholder="Görev"
                        v-model="employeselected.quest"
                        disabled
                      />
                    </b-form-group>
                  </b-col> </b-tab
              ></b-tabs>
              <b-col md="12">
                <b-card
                  style="background-color: #eeeeee"
                  title="Görevli Kişi Bilgileri"
                  v-if="this.gorevliCard == true"
                  ><b-row>
                    <h5>Adı: {{ gorevli.adi }}</h5>
                  </b-row>
                  <b-row>
                    <h5>Tel No: {{ gorevli.telNo }}</h5>
                  </b-row>
                  <b-row>
                    <h5>E-mail: {{ gorevli.eposta }}</h5>
                  </b-row>
                  <b-row>
                    <h5>Dahili No: {{ gorevli.dahiliNo }}</h5>
                  </b-row>
                </b-card>
              </b-col>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col cols="6">
                  <b-col cols="12">
                    <b-form-group>
                      <v-select
                        placeholder="Hastanın Rahatsızlığı"
                        class="select-size-sm"
                        v-model="callRequest.discomfortId"
                        :getOptionLabel="(discomforts) => discomforts.name"
                        :reduce="(discomforts) => discomforts.id"
                        :options="discomforts"
                        style="font-size: 12px"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <v-select
                        placeholder="Tedavi Bölümü"
                        class="select-size-sm"
                        v-model="callRequest.treatmentDepartmentId"
                        :getOptionLabel="
                          (treatmentDepartments) => treatmentDepartments.name
                        "
                        :reduce="
                          (treatmentDepartments) => treatmentDepartments.id
                        "
                        :options="treatmentDepartments"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <v-select
                        placeholder="Hastanın Talebi"
                        class="select-size-sm"
                        v-model="callRequest.wantId"
                        :getOptionLabel="(wants) => wants.name"
                        :reduce="(wants) => wants.id"
                        :options="wants"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <v-select
                        placeholder="İletişim Kanalı"
                        class="select-size-sm"
                        v-model="callRequest.contactChannelId"
                        :getOptionLabel="
                          (contactChannels) => contactChannels.name
                        "
                        :reduce="(contactChannels) => contactChannels.id"
                        :options="contactChannels"
                      />
                    </b-form-group>
                  </b-col>
                </b-col>
                <b-col cols="6">
                  <b-col cols="12">
                    <b-form-group>
                      <v-select
                        placeholder="Referans Kanalı"
                        class="select-size-sm"
                        v-model="callRequest.referanceChannelId"
                        :getOptionLabel="
                          (referanceChannels) => referanceChannels.name
                        "
                        :reduce="(referanceChannels) => referanceChannels.id"
                        :options="referanceChannels"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <v-select
                        placeholder="Sonuç"
                        class="select-size-sm"
                        v-model="callRequest.resultId"
                        :getOptionLabel="(results) => results.name"
                        :reduce="(results) => results.id"
                        :options="results"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <b-form-textarea
                        id="textarea-default"
                        placeholder="Açıklama"
                        rows="3"
                        v-model="callRequest.description"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-button
                      variant="success"
                      @click="hastaKayit"
                      style="float: right"
                    >
                      Kaydet
                    </b-button>
                  </b-col>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-card
                    style="background-color: #eeeeee; margin-top: 10px"
                    title="Randevular"
                  >
                    <b-col cols="12">
                      <b-row>
                        <table
                          v-for="randevu in randevular"
                          style="width: 250px"
                        >
                          <th>
                            <b-card class="header">
                              <h5>{{ randevu.message }}</h5>

                              <b-button
                                variant="danger"
                                @click="randevuiptalOnayla(randevu)"
                                style="margin-top: 10px"
                              >
                                İptal Et</b-button
                              >
                            </b-card>
                          </th>
                        </table></b-row
                      >
                    </b-col>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-card
                style="background-color: #eeeeee"
                title="Hasta Geçmiş Kayıtları"
              >
                <DxDataGrid
                  :data-source="hastaCagrilari"
                  :columns="columns"
                  :show-borders="true"
                  :word-wrap-enabled="true"
                  :show-row-lines="true"
                  :allow-column-reordering="true"
                  :allow-column-resizing="true"
                  height="350px"
                >
                  <DxScrolling column-rendering-mode="virtual" />
                  <DxHeaderFilter :visible="true" />
                  <DxFilterRow :visible="true" />
                  <DxFilterPanel :visible="true" />
                  <DxSorting mode="multiple" />
                </DxDataGrid>
              </b-card>
            </b-col>
          </b-row>

          <b-row> </b-row>
        </b-tab>
        <!-- Tab değişim -->
        <b-tab title="Randevu Al"
          ><RandevuComponent :callPatient="patientRequerst" />
        </b-tab>
      </b-tabs>
    </b-card>
    <b-modal
      v-model="show"
      ref="ekleKısayol"
      title="Hızlı Ekle"
      button-size="lg"
      cancel-title="Hayır"
      ok-title="Evet"
      no-close-on-backdrop
      hide-header-close
      centered
    >
      <HizliKayit :kayitUrl="kayitUrl" />
      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right"
            @click="popupClose()"
          >
            Tamam
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      ref="popupIptal"
      title="Randevu İptal Onayı"
      button-size="lg"
      cancel-title="Hayır"
      ok-title="Evet"
      @ok="randevuIptal()"
      centered
      ><b>İptal Edilen Randevu Bilgileri</b>
      <b-card-text>{{ this.selectedRandevu.message }} </b-card-text>
      <b-card-text>
        İptal etmek istediğiniz randevu bilgilerinin doğruluğundan eminmisiniz
        ?</b-card-text
      >
    </b-modal>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxSearchPanel,
  DxScrolling,
  DxPaging,
  DxFormItem,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxFilterPanel,
  DxSorting,
} from "devextreme-vue/data-grid";
import vSelect from "vue-select";
import {
  BCard,
  BCardText,
  BLink,
  BButtonGroup,
  BButton,
  BTab,
  BTabs,
  BFormInput,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormGroup,
  BFormSelect,
  BFormTextarea,
  VBModal,
  BModal,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { createStore } from "devextreme-aspnet-data-nojquery";
import globalSettings from "../settings";
import { formatDate } from "devextreme/localization";
import { data } from "vue-echarts";
import Ripple from "vue-ripple-directive";
import HizliKayit from "../views/HızliKayitComponent.vue";
import RandevuComponent from "../views/RandevuComponent.vue";

export default {
  components: {
    DxSorting,
    vSelect,
    BFormTextarea,
    BFormSelect,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxSearchPanel,
    DxScrolling,
    DxPaging,
    DxFormItem,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxFilterPanel,
    BButtonGroup,
    BButton,
    BCard,
    BTab,
    BTabs,
    BCardText,
    BLink,
    BFormInput,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BRow,
    BFormGroup,
    BCol,
    ToastificationContent,
    BModal,
    HizliKayit,
    RandevuComponent,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      kayitUrl: "",
      phoneAktif: false,
      firstNameAktif: false,
      lastNameAktif: false,
      show: false,
      patientRequerst: {
        phoneNumber: "",
        firstName: "",
        lastName: "",
      },
      callRequest: {
        provinceId: "",
        patientId: null,
        hospitalId: null,
        departmentId: null,
        questId: null,
        employeeId: null,
        discomfortId: null,
        treatmentDepartmentId: null,
        contactChannelId: null,
        referanceChannelId: null,
        wantId: null,
        districtId: null,
        resultId: null,
        description: "",
        isRelative: true,
        relativeFirstName: "",
        relativeLastName: "",
        incoming: true,
      },
      iptalRequest: {
        phoneNumber: "",
        apiPatientId: "",
        apiPatientKey: "",
        trackerCode: "",
        doctorCode: "",
        companyCode: "",
      },
      employeselected: {
        hospital: "",
        department: "",
        quest: "",
      },
      wants: [],
      referanceChannels: [],
      contactChannels: [],
      treatmentDepartments: [],
      provinces: [],
      districts: [],
      hospitals: [],
      departments: [],
      quests: [],
      employeQuests: [],
      calisanlar: [],
      hastaCagrilari: [],
      results: [],
      discomforts: [],
      selectedRandevu: "",
      gorevli: {
        dahiliNo: "",
        telNo: "",
        adi: "",
        eposta: "",
      },
      gorevliCard: true,
      columns: [
        {
          dataField: "dateCreated",
          caption: "Tarih",
          dataType: "datetime",
          width: "150px",
          sortOrder: "desc",
        },
        { dataField: "hospital.name", caption: "Hastane", width: "150px" },
        { dataField: "want.name", caption: "Hasta Talebi", width: "250px" },
        {
          dataField: "discomfort.name",
          caption: "Hasta Rahatsızlığı",
          width: "350px",
        },

        {
          dataField: "treatmentDepartment.name",
          caption: "Tedavi Bölümü",
          width: "150px",
        },

        { dataField: "description", caption: "Açıklama", width: "350px" },
        { dataField: "result.name", caption: "Sonuç", width: "150px" },

        {
          dataField: "quest.name",
          caption: "Departman Görevi",
          width: "150px",
        },
        { dataField: "employee.fullName", caption: "Çalışan", width: "180px" },
        {
          dataField: "createdBy",
          caption: "Çağrı Kayıt Personeli",
          width: "250px",
        },
      ],
      randevular: [],
    };
  },
  computed: {
    phoneNumber() {
      return this.patientRequerst.phoneNumber.length == 10 ? true : false;
    },
  },
  created() {
    this.patientRequerst.phoneNumber = window.location.search.replace(
      "?telNo=",
      ""
    );
    //console.log(this.patientRequerst.phoneNumber.length);
    if (this.patientRequerst.phoneNumber.length > 0) {
      this.hastaBilgileri();
      this.phoneAktif = true;
    }
    this.hastaneGetir();
    this.sehirGetir();
    //this.gorevGetir();
    this.hastaTalepleri();
    this.referansKanallari();
    this.iletisimKanallari();
    this.tedaviBolumleri();
    this.sonucGetir();
    this.hastaRahatsizliklari();
    this.tumcalisanlar();

    //discomfort
  },

  methods: {
    async gonderConsole() {
      console.log(this.callRequest);
    },
    async numaraSorgula() {
      this.hastaBilgileri();
    },
    async popupClose() {
      this.show = false;
      if (this.kayitUrl != "") {
        if (this.kayitUrl == "Want") {
          this.hastaTalepleri();
        }
        if (this.kayitUrl == "TreatmentDepartment") {
          this.tedaviBolumleri();
        }
        if (this.kayitUrl == "ReferanceChannel") {
          this.referansKanallari();
        }
        if (this.kayitUrl == "Result") {
          this.sonucGetir();
        }
        if (this.kayitUrl == "ContactChannel") {
          this.iletisimKanallari();
        }
        if (this.kayitUrl == "Discomfort") {
          this.hastaRahatsizliklari();
        }
      }
    },
    async popupOpen(data) {
      this.kayitUrl = data;
      this.show = true;
      // this.$refs["ekleKısayol"].show();
    },
    async hastaBilgileri() {
      if (this.patientRequerst.phoneNumber.length > 0) {
        var response = await this.$http
          .get(
            "/Patient/GetPatientByPhoneNumber?phoneNumber=" +
              this.patientRequerst.phoneNumber
          )
          .then((response) => {
            if (response.status == 200) {
              //console.log(response);
              this.phoneAktif = true;
              this.firstNameAktif = true;
              this.lastNameAktif = true;
              this.patientRequerst.firstName = response.data.firstName;
              this.patientRequerst.lastName = response.data.lastName;
              this.hastaCagrilariGetir(response.data);
              this.randevuGoster(response.data);
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Başarılı",
                    icon: "BellIcon",
                    text: "Doğrulama İşlemi Başarılı",
                    variant: "success",
                  },
                },
                {
                  position: "bottom-center",
                }
              );
            }
          })
          .catch((err) => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Yok",
                  icon: "BellIcon",
                  text: err.response.data.message,
                  variant: "danger",
                },
              },
              {
                position: "bottom-center",
              }
            );
          });
      }
    },
    async hastaCagrilariGetir(data) {
      var response = await this.$http
        .get("CallRequest/GetByPatientId?id=" + data.id)
        .then((response) => {
          if (response.status == 200) {
            this.hastaCagrilari = response.data;
            if (response.data.length == 0) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Yok",
                    icon: "BellIcon",
                    text: "Hastanın Çağrı Kaydı Yok",
                    variant: "danger",
                  },
                },
                {
                  position: "bottom-center",
                }
              );
            }
          }
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Yok",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
    },
    async hastaTalepleri() {
      var response = await this.$http
        .get("/Want")
        .then((response) => {
          if (response.status == 200) {
            this.wants = response.data;
            // console.log(response);
          }
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Yok",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
    },
    async referansKanallari() {
      var response = await this.$http
        .get("/ReferanceChannel")
        .then((response) => {
          if (response.status == 200) {
            this.referanceChannels = response.data;
            //console.log(this.wants);
          }
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Yok",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
    },
    async iletisimKanallari() {
      var response = await this.$http
        .get("/ContactChannel")
        .then((response) => {
          if (response.status == 200) {
            this.contactChannels = response.data;
            //console.log(this.wants);
          }
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Yok",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
    },
    async tedaviBolumleri() {
      var response = await this.$http
        .get("/TreatmentDepartment")
        .then((response) => {
          if (response.status == 200) {
            this.treatmentDepartments = response.data;
            //console.log(this.wants);
          }
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Yok",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
    },
    async hastaRahatsizliklari() {
      var response = await this.$http
        .get("/Discomfort")
        .then((response) => {
          if (response.status == 200) {
            this.discomforts = response.data;
            //console.log(this.wants);
          }
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Yok",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
    },
    async sehirGetir() {
      var response = await this.$http
        .get("/Province")
        .then((response) => {
          if (response.status == 200) {
            this.provinces = response.data;
            //console.log(this.wants);
          }
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Yok",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
    },
    async ilceGetir() {
      //let loader = this.$loading.show();
      var response = await this.$http
        .get("District/GetByProvinceId?id=" + this.callRequest.provinceId)
        .then((response) => {
          if (response.status == 200) {
            this.districts = response.data;
            //console.log(this.wants);
          }
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Yok",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
      //  loader.hide();
    },
    async hastaneGetir() {
      var response = await this.$http
        .get("/Hospital")
        .then((response) => {
          if (response.status == 200) {
            this.hospitals = response.data;
            //console.log(this.wants);
          }
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Yok",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
    },
    async departmanGetir() {
      //let loader = this.$loading.show();
      var response = await this.$http
        .get(
          "HospitalDepartment/GetByHospitalId?id=" + this.callRequest.hospitalId
        )
        .then((response) => {
          if (response.status == 200) {
            this.departments = response.data;
            //console.log(this.wants);
          }
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Yok",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
      //  loader.hide();
    },
    async gorevGetir() {
      var response = await this.$http
        .get(
          "DepartmentQuest/GetByDepartmentIdWithHospitalId?id=" +
            this.callRequest.departmentId +
            "&hospitalId=" +
            this.callRequest.hospitalId
        )
        .then((response) => {
          if (response.status == 200) {
            //console.log(response);
            this.quests = response.data;
            //console.log(this.wants);
          }
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Yok",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
    },
    async sonucGetir() {
      var response = await this.$http
        .get("/Result")
        .then((response) => {
          if (response.status == 200) {
            this.results = response.data;
            //console.log(this.wants);
          }
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Yok",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
    },
    async tumcalisanlar() {
      var response = await this.$http
        .get("Employee/Read")
        .then((response) => {
          if (response.status == 200) {
            this.calisanlar = response.data.data;
            //console.log(this.wants);
          }
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Yok",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
    },
    async tumcalisanbilgileri(dataId) {
      var response = await this.$http
        .get("Employee/GetInformationById?id=" + dataId)
        .then((response) => {
          if (response.status == 200) {
            //console.log(response);

            this.employeselected.hospital = response.data.emp.hospital.name;
            this.employeselected.department = response.data.dep.department.name;
            this.employeselected.quest = response.data.quest.quest.name;
            this.callRequest.hospitalId = response.data.emp.hospital.id;
            this.callRequest.departmentId = response.data.dep.department.id;
            this.callRequest.questId = response.data.quest.quest.id;
          }
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Yok",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
    },
    async gorevlileriGetir() {
      //let loader = this.$loading.show();
      var response = await this.$http
        .get(
          "EmployeeQuest/GetByQuestIdWithHospitalId?id=" +
            this.callRequest.questId +
            "&hospitalId=" +
            this.callRequest.hospitalId
        )
        .then((response) => {
          if (response.status == 200) {
            this.employeQuests = response.data;
            //console.log(this.wants);
          }
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Yok",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
      //  loader.hide();
    },
    async getGorevliBilgigoster(calisanlar) {
      var dataId = this.callRequest.employeeId;
      var kisi = [];
      calisanlar.filter(function (data) {
        if (data.id == dataId) {
          kisi = data;
        }
      });
      this.gorevli.dahiliNo = kisi.localPhoneNumber;
      this.gorevli.eposta = kisi.emailAddress;
      this.gorevli.adi = kisi.fullName;
      this.gorevli.telNo = kisi.phoneNumber;
      this.tumcalisanbilgileri(dataId);
      return;
    },
    async getGorevliBilgileri(employeQuests) {
      //let loader = this.$loading.show();
      //console.log(employeQuests);
      var dataId = this.callRequest.employeeId;
      var kisi = [];
      employeQuests.filter(function (data) {
        if (data.id == dataId) {
          kisi = data;
        }
      });
      this.gorevli.dahiliNo = kisi.localPhoneNumber;
      this.gorevli.eposta = kisi.emailAddress;
      this.gorevli.adi = kisi.fullName;
      this.gorevli.telNo = kisi.phoneNumber;

      return;
      //  gorevliBilgileri: {
      //   dahiliNo: "",
      //   telNo: "",
      //   adi: "",
      //   eposta: "",
      //  loader.hide();
    },
    async randevuGoster(data) {
      var response = await this.$http
        .get("Appointment/GetByPatientId?id=" + data.id)
        .then((response) => {
          if (response.status == 200) {
            // console.log(response, "randevular");
            this.randevular = response.data;
            if (response.data.length == 0) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Yok",
                    icon: "BellIcon",
                    text: "Hastanın Randevu Kaydı Yok",
                    variant: "danger",
                  },
                },
                {
                  position: "bottom-center",
                }
              );
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Başarılı",
                    icon: "BellIcon",
                    text: "Hastanın Randevu Bilgileri Getirildi",
                    variant: "success",
                  },
                },
                {
                  position: "bottom-center",
                }
              );
            }
          }
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Yok",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
    },
    async randevuIptal() {
      if (this.selectedRandevu.patient.apiPatientId == null) {
        this.iptalRequest.apiPatientId = 0;
      } else {
        this.iptalRequest.apiPatientId =
          this.selectedRandevu.patient.apiPatientId;
      }
      if (this.selectedRandevu.patient.apiPatientKey == null) {
        this.iptalRequest.apiPatientKey = "";
      } else {
        this.iptalRequest.apiPatientKey =
          this.selectedRandevu.patient.apiPatientKey;
      }
      // console.log(this.selectedRandevu);
      // return;
      this.iptalRequest.doctorCode = this.selectedRandevu.doctorId;
      this.iptalRequest.trackerCode = this.selectedRandevu.trackerCode;
      this.iptalRequest.companyCode =
        this.selectedRandevu.hospital.apiHospitalId;
      this.iptalRequest.phoneNumber = this.patientRequerst.phoneNumber;

      await this.$http
        .post(
          "https://randevuapi.romatem.com/api/Appointments/ManuelDelete",
          this.iptalRequest
        )
        .then((response) => {
          //console.log(response);
          this.hastaBilgileri();
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Başarılı",
                icon: "BellIcon",
                text: "Randevu İptal Başarılı",
                variant: "success",
              },
            },
            {
              position: "bottom-center",
            }
          );
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Hata !",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
    },
    async randevuiptalOnayla(data) {
      this.selectedRandevu = data;
      this.$refs["popupIptal"].show(data);
    },
    async hastaKayit() {
      // ilk önce hasta kaydı kontrolleri

      if (
        this.patientRequerst.phoneNumber == null ||
        this.patientRequerst.phoneNumber == ""
      ) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Hata !",
              icon: "BellIcon",
              text: "Lütfen Telefon Numarası Giriniz",
              variant: "danger",
            },
          },
          {
            position: "bottom-center",
          }
        );
        this.show = false;
        return;
      }
      if (
        this.patientRequerst.firstName == null ||
        this.patientRequerst.firstName == ""
      ) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Hata !",
              icon: "BellIcon",
              text: "Lütfen Hasta Adını Giriniz",
              variant: "danger",
            },
          },
          {
            position: "bottom-center",
          }
        );
        this.show = false;
        return;
      }
      if (
        this.patientRequerst.lastName == null ||
        this.patientRequerst.lastName == ""
      ) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Hata !",
              icon: "BellIcon",
              text: "Lütfen Hasta Soyadını Giriniz",
              variant: "danger",
            },
          },
          {
            position: "bottom-center",
          }
        );
        this.show = false;
        return;
      }

      // Sonra Çağrı kaydı kontrolleri
      // if (
      //   this.callRequest.provinceId == null ||
      //   this.callRequest.provinceId == ""
      // ) {
      //   this.$toast(
      //     {
      //       component: ToastificationContent,
      //       props: {
      //         title: "Hata !",
      //         icon: "BellIcon",
      //         text: "Lütfen Şehir Seçiniz",
      //         variant: "danger",
      //       },
      //     },
      //     {
      //       position: "bottom-center",
      //     }
      //   );
      //   this.show = false;
      //   return;
      // }
      // if (
      //   this.callRequest.districtId == 0 ||
      //   this.callRequest.districtId == ""
      // ) {
      //   this.$toast(
      //     {
      //       component: ToastificationContent,
      //       props: {
      //         title: "Hata !",
      //         icon: "BellIcon",
      //         text: "Lütfen İlçe Seçiniz",
      //         variant: "danger",
      //       },
      //     },
      //     {
      //       position: "bottom-center",
      //     }
      //   );
      //   this.show = false;
      //   return;
      // }
      if (
        this.callRequest.hospitalId == null ||
        this.callRequest.hospitalId == ""
      ) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Hata !",
              icon: "BellIcon",
              text: "Lütfen Hastane Seçiniz",
              variant: "danger",
            },
          },
          {
            position: "bottom-center",
          }
        );
        this.show = false;
        return;
      }
      if (this.callRequest.isRelative == false) {
        if (
          this.callRequest.relativeFirstName == "" ||
          this.callRequest.relativeLastName == ""
        ) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Hata !",
                icon: "BellIcon",
                text: "Lütfen Hasta Yakını Bilgileri Doldurunuz.",
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
          this.show = false;
          return;
        }
      }
      // if (
      //   this.callRequest.departmentId == 0 ||
      //   this.callRequest.departmentId == ""
      // ) {
      //   this.$toast(
      //     {
      //       component: ToastificationContent,
      //       props: {
      //         title: "Hata !",
      //         icon: "BellIcon",
      //         text: "Lütfen Departman Seçiniz",
      //         variant: "danger",
      //       },
      //     },
      //     {
      //       position: "bottom-center",
      //     }
      //   );
      //   this.show = false;
      //   return;
      // }
      // if (this.callRequest.questId == 0 || this.callRequest.questId == "") {
      //   this.$toast(
      //     {
      //       component: ToastificationContent,
      //       props: {
      //         title: "Hata !",
      //         icon: "BellIcon",
      //         text: "Lütfen Görev Seçiniz",
      //         variant: "danger",
      //       },
      //     },
      //     {
      //       position: "bottom-center",
      //     }
      //   );
      //   this.show = false;
      //   return;
      // }
      // if (
      //   this.callRequest.employeeId == 0 ||
      //   this.callRequest.employeeId == ""
      // ) {
      //   this.$toast(
      //     {
      //       component: ToastificationContent,
      //       props: {
      //         title: "Hata !",
      //         icon: "BellIcon",
      //         text: "Lütfen Görevli Kişi Seçiniz",
      //         variant: "danger",
      //       },
      //     },
      //     {
      //       position: "bottom-center",
      //     }
      //   );
      //   this.show = false;
      //   return;
      // }
      // if (
      //   this.callRequest.discomfortId == 0 ||
      //   this.callRequest.discomfortId == ""
      // ) {
      //   this.$toast(
      //     {
      //       component: ToastificationContent,
      //       props: {
      //         title: "Hata !",
      //         icon: "BellIcon",
      //         text: "Lütfen Hastanın Rahatsızlığını Seçiniz",
      //         variant: "danger",
      //       },
      //     },
      //     {
      //       position: "bottom-center",
      //     }
      //   );
      //   this.show = false;
      //   return;
      // }
      // if (
      //   this.callRequest.treatmentDepartmentId == 0 ||
      //   this.callRequest.treatmentDepartmentId == ""
      // ) {
      //   this.$toast(
      //     {
      //       component: ToastificationContent,
      //       props: {
      //         title: "Hata !",
      //         icon: "BellIcon",
      //         text: "Lütfen Tedavi Bölümü Seçiniz",
      //         variant: "danger",
      //       },
      //     },
      //     {
      //       position: "bottom-center",
      //     }
      //   );
      //   this.show = false;
      //   return;
      // }
      // if (
      //   this.callRequest.contactChannelId == 0 ||
      //   this.callRequest.contactChannelId == ""
      // ) {
      //   this.$toast(
      //     {
      //       component: ToastificationContent,
      //       props: {
      //         title: "Hata !",
      //         icon: "BellIcon",
      //         text: "Lütfen İletişim Kanalı Seçiniz",
      //         variant: "danger",
      //       },
      //     },
      //     {
      //       position: "bottom-center",
      //     }
      //   );
      //   this.show = false;
      //   return;
      // }
      // if (
      //   this.callRequest.referanceChannelId == 0 ||
      //   this.callRequest.referanceChannelId == ""
      // ) {
      //   this.$toast(
      //     {
      //       component: ToastificationContent,
      //       props: {
      //         title: "Hata !",
      //         icon: "BellIcon",
      //         text: "Lütfen Referans Kanalı Seçiniz",
      //         variant: "danger",
      //       },
      //     },
      //     {
      //       position: "bottom-center",
      //     }
      //   );
      //   this.show = false;
      //   return;
      // }
      // if (this.callRequest.wantId == 0 || this.callRequest.wantId == "") {
      //   this.$toast(
      //     {
      //       component: ToastificationContent,
      //       props: {
      //         title: "Hata !",
      //         icon: "BellIcon",
      //         text: "Lütfen Hasta Talebi Seçiniz",
      //         variant: "danger",
      //       },
      //     },
      //     {
      //       position: "bottom-center",
      //     }
      //   );
      //   this.show = false;
      //   return;
      // }
      if (this.callRequest.resultId == 0 || this.callRequest.resultId == "") {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Hata !",
              icon: "BellIcon",
              text: "Lütfen Sonuç Seçiniz",
              variant: "danger",
            },
          },
          {
            position: "bottom-center",
          }
        );
        this.show = false;
        return;
      }
      // if (
      //   this.callRequest.description == null ||
      //   this.callRequest.description == ""
      // ) {
      //   this.$toast(
      //     {
      //       component: ToastificationContent,
      //       props: {
      //         title: "Hata !",
      //         icon: "BellIcon",
      //         text: "Lütfen Açıklama Gİriniz",
      //         variant: "danger",
      //       },
      //     },
      //     {
      //       position: "bottom-center",
      //     }
      //   );
      //   this.show = false;
      //   return;
      // }

      await this.$http
        .post("/Patient", this.patientRequerst)
        .then((response) => {
          //  console.log(response);
          if (response.status == 200) {
            this.callRequest.patientId = response.data.id;
            this.cagriKayit();
          }
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Hata !",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
    },
    async cagriKayit() {
      await this.$http
        .post("/CallRequest", this.callRequest)
        .then((response) => {
          if (response.status == 200) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Başarılı ",
                  icon: "BellIcon",
                  text: "Kayıt Başarıyla Gerçekleştirildi",
                  variant: "success",
                },
              },
              {
                position: "bottom-center",
              }
            );

            window.close();
          }
        })
        .catch((err) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Hata !",
                icon: "BellIcon",
                text: err.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-center",
            }
          );
        });
    },
  },
};
</script>

<style></style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
